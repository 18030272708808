<template>
    <div class="prove-school">
      <div class="chat-head">
        <div class="head-left">
          <el-image class="head-icon" :src="require(`../../../assets/image/user.png`)" fit="contain"></el-image>
          <div class="head-title">学校认证</div>
        </div>
        <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
      </div>
      <!-- 表单数据 -->
      <div class="myform">
        <el-form :model="userForm" ref="rulForm" :rules="rules">
          <div class="form-top">
            <div v-if="is_attestation" style="margin-bottom: 10px;font-size: 18px;color: brown;">已认证</div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="学校" prop="ai_chain_id_new">
                  <el-select
                    :disabled="is_attestation"
                    class="form-input"
                    v-model="userForm.ai_chain_id_new"
                    :filterable="true"
                    :remote="true"
                    placeholder="请搜索学校名称"
                    :remote-method="remoteMethod"
                    :loading="loading"
                    size="small"
                    @change="remoteChain"
                  >
                    <el-option v-for="item in chainData" :key="item.shop_user_ai_chain_id" :label="item.chain_name" :value="item.shop_user_ai_chain_id"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="班级" prop="college">
                  <el-input class="form-input" :disabled="is_attestation" size="small" v-model="userForm.college" placeholder="请输入班级"></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="学号" prop="number">
                  <el-input class="form-input" :disabled="is_attestation" size="small" v-model="userForm.number" placeholder="请输入学号"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="入学时间" prop="enrollment">
                  <el-input class="form-input" :disabled="is_attestation" size="small" v-model="userForm.enrollment" placeholder="请输入入学时间"></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="学历" prop="education">
                  <el-input class="form-input" :disabled="is_attestation" size="small" v-model="userForm.education" placeholder="请输入学历"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-compo">
              <div class="form-compo-div">
                <el-form-item label="工作年龄" prop="job_age">
                  <el-input class="form-input" :disabled="is_attestation" size="small" v-model="userForm.job_age" placeholder="请输入工作年龄"></el-input>
                </el-form-item>
              </div>
              <div class="form-compo-div">
                <el-form-item label="年龄" prop="age">
                  <el-input class="form-input" :disabled="is_attestation" size="small" v-model="userForm.age" placeholder="请输入年龄"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="form-button">
            <el-form-item>
              <el-button type="warning" size="mini" @click="submitForm">认证</el-button>
              <el-button size="mini" @click="resetForm">返回</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
import { aigetlistQuery, getAiUserDetails, insertSchoolDetails } from '../../../request/api'

  export default {
    data() {
      return {
        userForm: {
          ai_chain_id_new: '',
          school: '',
          college: '',
          number: '',
          enrollment: '',
          education: '',
          job_age: '',
          age: ''
        },
        rules: {
          ai_chain_id_new: [
            { required: true, message: '请选择学校', trigger: 'blur' },
          ],
          college: [
            { required: true, message: '请输入班级', trigger: 'blur' },
          ],
          number: [
            { required: true, message: '请输入学号', trigger: 'blur' }
          ],
          enrollment: [
            { required: true, message: '请输入入学时间', trigger: 'blur' }
          ],
          education: [
            { required: true, message: '请输入学历', trigger: 'blur' }
          ],
          job_age: [
            { required: true, message: '请输入工作年龄', trigger: 'blur' }
          ],
          age: [
            { required: true, message: '请输入年龄', trigger: 'blur' }
          ]
        },
        chainData: [],
        loading: false,
        is_attestation: false
      }
    },
    methods: {
      sendExit() {
        this.$emit('update-value')
      },
      submitForm() {
        if (this.is_attestation) return this.$message.warning('已经认证过了')

        this.$refs.rulForm.validate((valid) => {
          if (valid) {
            this.$confirm('认证后不可更改，是否继续？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              try {
                const res = await insertSchoolDetails(this.userForm)
                if (res.status !== 200 || res.data.code !== "1000") {
                  return this.$message.error(res.data.msg);
                }

                this.init()
                this.$message.success('认证成功')
              } catch(error) {
                this.$message.error('提交失败', error);
              }
            }).catch(() => {
              this.$message({ type: 'info', message: '已取消认证' })
            })
          } else {
            console.log('error submit!!')
            return false
          }

        })
      },
      remoteChain(id) {
        this.chainData.forEach(ele => {
          if (ele.shop_user_ai_chain_id === id) {
            this.userForm.school = ele.chain_name
          }
        })
      },
      async remoteMethod(query) {
        this.loading = true
        try {
          const res = await aigetlistQuery(query)
          if (res.status !== 200 || res.data.code !== "1000") {
            return this.$message.error(res.data.msg);
          }

          this.chainData = res.data.data
          this.loading = false
        } catch(error) {
          this.$message.error('请求发生错误：' + error.message);
        }
      },
      // 返回
      resetForm() {
        this.$router.back()
      },
      // 初始化操作
      async init() {
        const res = await getAiUserDetails()
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        const details = res.data.data
        if (details.getAiChain[0].chain_name !== '游客') this.is_attestation = true
        this.chainData = details.getAiChain

        this.userForm.ai_chain_id_new = details.getAiChain[0].shop_user_ai_chain_id
        this.userForm.school = details.getAiChain[0].chain_name

        if (details.getAiUser === null) return
        if (details.getAiUser.college !== null) this.userForm.college = details.getAiUser.college
        if (details.getAiUser.number !== null) this.userForm.number = details.getAiUser.number
        if (details.getAiUser.enrollment !== null) this.userForm.enrollment = details.getAiUser.enrollment
        if (details.getAiUser.education !== null) this.userForm.education = details.getAiUser.education
        if (details.getAiUser.job_age !== null) this.userForm.job_age = details.getAiUser.job_age
        if (details.getAiUser.age !== null) this.userForm.age = details.getAiUser.age
      }
    },
    mounted() {
      this.init()
    }
  }
  </script>
  
  <style lang="less" scoped>
  .prove-school {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .myform {
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 40%;
            .form-input {
              width: 66%;
            }
          }
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
    .chat-head {
      height: 8%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .head-left {
        display: flex;
        align-items: center;
        .head-icon {
          width: 40px;
          margin-right: 6px;
        }
        .head-title {
          font-size: 14px;
          color: #7a7c7e;
        }
      }
      .head-right {
        padding-right: 1%;
        width: 70px;
        cursor: pointer;
      }
    }
  }
  </style>